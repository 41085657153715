import StatsSender from '@hh.ru/stats-sender';
import Cookies from 'bloko/common/Cookies';

const sender = new StatsSender(
    'xhh',
    () => ({
        isWebView: window.globalVars.isWebView,
        pageName: window.globalVars.luxPageName,
        xsrf: Cookies.get('_xsrf') || '',
    }),
    '/shards/stat'
);

export default sender;
